<template>
  <div :id="id" :class="['bg-white border rounded-lg mb-4 overflow-visible', isHidden ? 'overflow-hidden' : '']">
    <div class="py-6" :class="bodyPadding ? 'md:pb-6' : 'pb-0'">
      <div class="section-header px-4 md:px-8">
        <h2 class="text-lg leading-6 font-bold text-gray-800">{{ title }}</h2>
        <p v-if="description" class="mt-1 text-sm text-gray-500 mt-3 mb-6" v-html="description"></p>
      </div>
      <div class="action-area mt-3" :class="bodyPadding ? 'px-4 md:px-8' : 'px-0'">
        <slot />
      </div>
    </div>
    <div class="section-footer border-t bg-gray-100 px-6 py-2">
      <div class="flex justify-between items-center">
        <span v-if="info" class="text-sm text-gray-600">{{ info }}</span>
        <span v-else></span>
        <div>
          <button
            v-if="showSkipButton"
            class="btn btn-white text-sm"
            @click="$emit('saveSection')">
            <span>Skip</span>
          </button>
          <button
            v-if="showSaveButton"
            class="btn-save-section ml-2"
            :disabled="!isValidSection"
            @click="$emit('saveSection')">
            <LoadingSpinner v-if="loading" class="w-5 h-5" outer-class="text-white" />
            <span v-else>Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";

export default {
  name: "SectionWrapper",
  components: {
    LoadingSpinner
  },
  props: {
    id: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    info: {
      type: String,
      default: null
    },
    bodyPadding: {
      type: Boolean,
      default: true
    },
    isValidSection: {
      type: [Boolean, Object],
      default: true
    },
    showSaveButton: {
      type: Boolean,
      default: true
    },
    showSkipButton: {
      type: Boolean,
      default: false
    },
    isHidden: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['saveSection']
};
</script>

<style scoped></style>
